import CompactHeader from '../../../../../local/templates/megamart_default/resources/js/modules/compactHeader';
import DLMenu from '../../../../../local/templates/megamart_default/resources/js/modules/dlMenu';

import $ from 'jquery'

var instance = null;

function getInstance() {
	let selector = (RS.Options || {}).compactHeaderSelector;

	if (!instance)
	{
		if (selector)
		{
			let header = document.querySelector(selector);
			instance = new CompactHeader(header, {
				selectors: {
					root: 'body',
					wrapper: 'body > main',
					menu: '.l-compact-menu',
					toggleButton: '[data-compact-menu-toggle]',
					search: '#compact-title-search'
				}
			});
		}
	}
	else
	{
		instance.resolveSelectors();
	}

	return instance;
}

function init() {
	const compactHeader = getInstance();

	// Fixing header
	if((RS.Options || {}).fixingCompactHeader) {
		compactHeader.fixing();
	}

	// Toggle compact menu
	$(document).on('click', '[data-compact-menu-toggle]', function(event) {
		event.preventDefault();

		compactHeader.toggleMenu(this);
	});

	// Mobile search
	$(document).on('click', '[data-compact-search-open]', function(event) {
		event.preventDefault();

		compactHeader.revealMobileSearch();
	});

	$(document).on('click', '[data-compact-search-close]', (event) => {
		event.preventDefault();

		compactHeader.concealMobileSearch();
	});

	$(window).resize(() => {
		if (compactHeader.$search.hasClass('js-is-open')) {
			compactHeader.concealMobileSearch();
		}
	});
}

BX.ready(function(){
	init();

	let obDLMenu = document.querySelectorAll('.b-dl-menu');

	for (let i in obDLMenu)
	{
		// debugger;
		if (obDLMenu.hasOwnProperty(i))
		{
			let obDLMenuSubmenu = obDLMenu[i].querySelectorAll('ul');
			for (let j in obDLMenuSubmenu)
			{
				if (obDLMenuSubmenu.hasOwnProperty(j))
				{
					obDLMenuSubmenu[j].parentNode.classList.add('has-subitems');

					let obPrevlink = obDLMenuSubmenu[j].previousElementSibling.cloneNode(true);

					let obPrevItem = document.createElement('li');
					obPrevItem.classList.add('b-dl-menu__item', 'b-dl-menu__item--main');

					obPrevItem.prepend(obPrevlink);

					obDLMenuSubmenu[j].prepend(obPrevItem);

					let obBackItem = document.createElement('li');
					obBackItem.classList.add('b-dl-menu__item', 'b-dl-menu__item--back');

					let obBackItemLink = document.createElement('li');
					obBackItemLink.classList.add('b-dl-menu__link', 'is-back');
					obBackItemLink.setAttribute('href', '#');
					obBackItemLink.innerHTML = 'Back';

					obBackItem.prepend(obBackItemLink);

					obDLMenuSubmenu[j].prepend(obBackItem);
				}
			}

			new DLMenu($(obDLMenu[i]), {
				selectors: {
					parent: '.l-compact-menu'
				}
			});
		}
	}


 });
